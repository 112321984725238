import React from 'react';
import { Container, Row, Column } from '../Grid';
import Icon from '../Icon';
import classNames from 'classnames';
import './styles.scss';

const AccordionItem = ({
  accordionTitle,
  accordionIntro,
  children,
  show,
  onClick,
  citation,
  citeNum,
  id,
  className,
}) => {
  let number = { test: citeNum };
  const anchor = 'anchor' + number.test;

  return (
    <Container className="accordion__container" id={id}>
      <Row className="accordion__title" onClick={onClick} id={anchor}>
        <Column size={10} className="accordion__column">
          <h3 className="h3">{accordionTitle}</h3>
          {show ? (
            <Icon name="caratdown" marginLeft className="accordion__icon" />
          ) : (
            <Icon name="caratright" marginLeft className="accordion__icon" />
          )}
          <div className="accordion__gradient-rule"></div>
        </Column>
      </Row>
      {children && (
        <Row
          className={classNames(
            'accordion__content-wrap ml-3',
            show || 'accordion__hidden'
          )}
        >
          <Column size={8}>
            <p className="accordion__intro">
              {accordionIntro}{' '}
              <a href={citation}>
                <sup>{citeNum}</sup>
              </a>
            </p>
          </Column>
          {children}
        </Row>
      )}
    </Container>
  );
};

export default AccordionItem;
