import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Column } from '../Grid';
import Section from '../Section';
import AccordionItem from '../AccordionItem';
import Button from '../Button';
import './styles.scss';
import OutboundLink from '../OutboundLink';

const Accordion = () => {
  // Set number of accordion segments
  const numAccordions = 5;
  //state variable for expand/collapse ALL accordions
  const [accordionsExpanded, setAccordionsExpanded] = useState(false);
  //array to hold state of EACH accordion
  const [allAccordionStates, setAllAccordionStates] = useState(() =>
    Array(numAccordions).fill(accordionsExpanded)
  );
  //handle expand/collapse all
  const toggleAccordions = () => {
    setAccordionsExpanded((currentState) => !currentState);
    setAllAccordionStates(Array(numAccordions).fill(!accordionsExpanded));
  };

  //handle individual toggle
  const toggleAccordionAtIndex = (index) => {
    setAllAccordionStates([
      ...allAccordionStates.slice(0, index),
      !allAccordionStates[index],
      ...allAccordionStates.slice(index + 1, allAccordionStates.length),
    ]);
  };

  //capture state of accordions to change button
  const allAccordionsExpanded = () => {
    return allAccordionStates.reduce((res, cur) => res && cur, true);
  };

  // ALL ACCORDION COMPONENTS HERE
  const renderCurrentData = () => {
    return (
      <Container>
        <Row id="accordion">
          <Column>
            <Button link onClick={() => toggleAccordions()}>
              {accordionsExpanded || allAccordionsExpanded()
                ? 'Collapse All'
                : 'Expand All'}
            </Button>
          </Column>
        </Row>
        {/* 1. ECONOMIC STABILITY */}
        <AccordionItem
          accordionTitle="1. Economic Stability"
          accordionIntro="The resources needed to support a healthy life, like affordable housing, employment that provides a living wage, reliable transportation to work, and favorable workplace conditions and protection."
          citation="/citations#cite9"
          citeNum="9"
          id="accordion1"
          show={allAccordionStates[0]}
          onClick={() => toggleAccordionAtIndex(0)}
        >
          <Column
            size={5}
            className="accordion__content mr-1 border-dark"
            id="anchor9"
          >
            <h4 className="h4 mb-0">Key Challenges</h4>
            <ol>
              <li id="anchor10">
                Improved global health depends on achieving greater social
                justice, economic stability, and effective access for basic
                health care and education around the world.{' '}
                <sup>
                  <a href="/citations#cite10">10</a>
                </sup>
              </li>
              <li>
                Half the world's population cannot afford the costs of quality
                healthcare and many do not have access to basic medical care.
                <sup>
                  <a href="/citations#cite11">11</a>
                </sup>
              </li>
            </ol>
          </Column>
          <Column size={5} className="accordion__content border-red">
            <h4 className="h4 mb-0">Local Actions</h4>
            <ol>
              <li>
                Support businesses in your area who give back to the community.
              </li>
              <li>Buy local goods, fresh fruits and vegetables.</li>
              <li>
                Shop globally sustainable businesses and encourage others to do
                the same.
              </li>
              <li>
                Volunteer with local organizations that support initiatives
                which improve access to healthcare, healthy foods, and
                education.
              </li>
            </ol>
          </Column>
        </AccordionItem>
        {/* 2. Health Care Access and Quality  */}
        <AccordionItem
          accordionTitle="2. Health Care Access and Quality"
          accordionIntro="The timely use and access of personal health services to achieve the best health outcomes."
          citation="/citations#cite1"
          citeNum="12"
          show={allAccordionStates[1]}
          onClick={() => toggleAccordionAtIndex(1)}
          id="accordion2"
        >
          <Column size={5} className="accordion__content mr-1 border-dark">
            <h4 className="h4 mb-0">Key Challenges</h4>
            <ol>
              <li id="anchor13">
                Delivery of proper treatments and medications for common health
                conditions is difficult around the world due to poor health
                services, mistrust of health systems, exclusion, and social
                divisions.{' '}
                <sup>
                  <a href="/citations#cite13">13</a>
                </sup>
              </li>
              <li>
                Ideas and resources to improve health care process, access, and
                quality are not being put forth due to illiteracy, lack of
                health literacy materials that are culturally competent, and
                lack of outreach opportunities.
              </li>
            </ol>
          </Column>
          <Column size={5} className="accordion__content border-red">
            <h4 className="h4 mb-0">Local Actions</h4>
            <ol>
              <li>
                Volunteer with health workers and learn to prevent and treat
                simple diseases or conditions prevalent in your area.
              </li>
              <li>
                Volunteer with organizations who provide patients and health
                care providers with health literate and culturally competent
                educational materials.
              </li>
            </ol>
          </Column>
        </AccordionItem>
        {/* 3. Social and Community Context  */}
        <AccordionItem
          accordionTitle="3. Social and Community Context"
          accordionIntro="The social support people need in the places where they live, work, learn, and play."
          citation="/citations#cite2"
          citeNum="14"
          show={allAccordionStates[2]}
          onClick={() => toggleAccordionAtIndex(2)}
          id="accordion3"
        >
          <Column size={5} className="accordion__content mr-1 border-dark">
            <h4 className="h4 mb-0">Key Challenges</h4>
            <ol>
              <li>The main concerns include:</li>
              <ul className="accordion__sublist">
                <li>concerns over physical and mental health</li>
                <li>proper medication administration</li>
                <li>poor communication from care provider</li>
              </ul>
            </ol>
          </Column>
          <Column size={5} className="accordion__content border-red">
            <h4 className="h4 mb-0">Local Actions</h4>
            <ol>
              <li>
                Make an impact by volunteering at local shelters or supporting
                non-profit groups that are working toward health equity.
              </li>
              <li>
                Work with your family and friends to make smarter health choices
                by learning how to prevent non-communicable diseases like
                diabetes. More than half of all NCD deaths begin with behaviors
                that start during the teenage years.
              </li>
            </ol>
          </Column>
        </AccordionItem>
        {/* 4. Neighborhood and Built Environment  */}
        <AccordionItem
          accordionTitle="4. Neighborhood and Built Environment"
          accordionIntro="The human-made surroundings (built environment) that provide the setting for human activity, like neighborhoods, have an impact on our health and well-being."
          citation="/citations#cite15"
          citeNum="15"
          show={allAccordionStates[3]}
          onClick={() => toggleAccordionAtIndex(3)}
          id="accordion4"
        >
          <Column size={5} className="accordion__content mr-1 border-dark">
            <h4 className="h4 mb-0">Key Challenges</h4>
            <ol>
              <li id="anchor16">
                Most of the world's people are at risk from&nbsp;
                <OutboundLink to="https://extranet.who.int/kobe_centre/en/news/Vectors_20140401">
                  vector-borne
                </OutboundLink>
                &nbsp;diseases, such as malaria.
                <sup>
                  <a href="/citations#cite16">16</a>
                </sup>
                .
              </li>
              <li id="anchor17">
                Poor environmental and sanitation conditions cause frequent
                disease outbreaks.
                <sup>
                  <a href="/citations#cite17">17</a>
                </sup>
              </li>
              <li id="anchor18">
                Most cities worldwide have air pollution levels more than 3X the
                WHO limits for air quality.
                <sup>
                  <a href="/citations#cite18">18</a>
                </sup>
              </li>
            </ol>
          </Column>
          <Column size={5} className="accordion__content border-red">
            <h4 className="h4 mb-0">Local Actions</h4>
            <ol>
              <li>
                Ask local officials for community green spaces or gardens for
                people to meet, play, cycle, and walk safely and to make health
                equity issues, such as clean water and air, a priority.
              </li>
              <li>
                Learn and share information about making houses&nbsp;
                <OutboundLink to="https://www.who.int/news-room/fact-sheets/detail/vector-borne-diseases">
                  vector-free
                </OutboundLink>
                , both in local neighborhoods and in newer urban areas.
              </li>
            </ol>
          </Column>
        </AccordionItem>
        {/* 5. Education Access and Quality  */}
        <AccordionItem
          accordionTitle="5. Education Access and Quality"
          accordionIntro="Good health information and critical thinking skills can reduce inequalities by improving health literacy and strengthening essential life skills."
          citation="/citations#cite19"
          citeNum="19"
          show={allAccordionStates[4]}
          onClick={() => toggleAccordionAtIndex(4)}
          id="accordion5"
        >
          <Column size={5} className="accordion__content mr-1 border-dark">
            <h4 className="h4 mb-0">Key Challenges</h4>
            <ol>
              <li id="anchor20">
                Many of the world’s children and youth are out of school. The
                proportion is very large in developing countries.
                <sup>
                  <a href="/citations#cite20">20</a>
                </sup>
              </li>
              <li id="anchor21">
                Educational and health challenges coexist with other social
                challenges. These can lead to school dropouts or other risky
                behaviors.
                <sup>
                  <a href="/citations#cite21">21</a>
                </sup>
              </li>
            </ol>
          </Column>
          <Column size={5} className="accordion__content border-red">
            <h4 className="h4 mb-0">Local Actions</h4>
            <ol>
              <li>
                Volunteer to help people with learning deficiencies or health
                challenges that affect learning.
              </li>
              <li>
                Be vocal about inequities in your community that create learner
                disadvantages.
              </li>
              <li>
                Contact policy makers about ensuring that quality educational
                opportunities are open to everyone.
              </li>
            </ol>
          </Column>
        </AccordionItem>
      </Container>
    );
  };

  return <Section>{renderCurrentData()}</Section>;
};

Accordion.propTypes = {
  className: PropTypes.string,
};
Accordion.defaultProps = {
  className: null,
};
export default Accordion;
