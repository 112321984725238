import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Subfooter from '../components/Subfooter';
import Icon from '../components/Icon';
import OutboundLink from '../components/OutboundLink';
import { Link } from 'gatsby';
import Carousel from '../components/Carousel';
import Accordion from '../components/Accordion';

const HealthEquity = ({ data }) => (
  <Layout title="About the Program">
    {/* Header section */}
    <Section className="about-mission">
      <Container>
        <h1 className="h1">Understanding Health Equity</h1>
        <h3 className="h3">What is health equity and why does it matter?</h3>
        <p>
          Health equity is when everyone, everywhere, has the opportunity to be
          their best and healthiest self regardless of where they live or grow.
          Health equity is a goal and something we strive to achieve because we
          all want to be healthy, happy, and live up to our full potential.
          <br />
          <br />
          Where a person grows and lives greatly impacts how long and how well
          they live. An individual’s environment often has a greater influence
          on overall health than many medical factors.
        </p>
      </Container>
    </Section>
    {/* Carousel */}
    <Section>
      <Container id="slidecites">
        <Carousel
          settings={{
            dots: true,
          }}
          slides={[
            {
              image: 'healthequity-carousel-slide1.svg',
            },
            {
              image: 'healthequity-carousel-slide2.svg',
            },
            {
              image: 'healthequity-carousel-slide3.svg',
            },
            {
              image: 'healthequity-carousel-slide4.svg',
            },
            {
              image: 'healthequity-carousel-slide5.svg',
            },
            {
              image: 'healthequity-carousel-slide6.svg',
            },
          ]}
        />
      </Container>
    </Section>
    {/* Main Content */}
    <Section>
      <Container>
        <Row className="mb-5">
          <Column size={6}>
            <h3 className="h3">Championing Health Equity</h3>
            <p>
              Together we can champion better health and become advocates within
              our own communities, creating a global movement of change.
              Achieving health equity is not something that one person or group
              can solve alone. Global health equity is based on multiple factors
              and can only be achieved when we work together and address the
              conditions that impact the social determinants of health (SDOH).
            </p>
          </Column>
          <Column size={5} offset={1}>
            <Image filename="healthequity-image-Championing.jpg" />
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column size={6}>
            <h3 className="h3">What are the social determinants of health?</h3>
            <p>
              Most of what contributes to overall health is based on five areas
              called the social determinants of health. SDOH impact the
              environment in which people are born, grow, live, work, plan, and
              age. Use the information below to learn more about the SDOH and
              discover how to become a health equity champion by reviewing the
              key challenges and possible local actions that you can take to
              impact the world around you.
            </p>
          </Column>
          <Column size={5} offset={1}>
            <Image filename="healthequity-image-SDoHchart@2x.png" />
          </Column>
        </Row>
      </Container>
    </Section>

    {/* Accordion Sections */}
    <Accordion></Accordion>

    {/* CALLOUT */}
    <Section className="healthequity-callout__border ml-1 mr-1 mt-2">
      <OutboundLink to="/patient-affairs">
        <Container>
          <Row>
            <h2 className="healthequity-callout__text">
              Learn more about a career incorporating health equity
              <Icon name="caratright" marginLeft />
            </h2>
          </Row>
        </Container>
      </OutboundLink>
    </Section>
    {/* Quote footer */}
    <Subfooter
      className="subfooter__quote-health"
      footerImage="healthequity-image-QuoteBox.jpg"
      footerText="Health cannot be a question of income; it is a fundamental human right."
      footerSourceOne={'Nelson Mandela'}
      textRed
    />
  </Layout>
);

export default HealthEquity;
